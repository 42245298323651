import React from "react"
import { graphql, Link} from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import CurveHero from "../components/CurveHero"
import { GatsbyImage } from "gatsby-plugin-image"

export const query = graphql`
  query {
    wp: wpPage(slug: {eq: "timeline"}) {
      timeline {
        linkFuture {
          heading
          desc
          img {
            gatsbyImage(width: 300)
          }
        }
        entries: timeline {
          timelineContent
          timelineHeading
          asxLinks {
            asxUrl {
              url
              title
            }
          }
          timelineImage {
            gatsbyImage(width:600)
          }
          project {
          ... on WpPage {
            id
            uri
            title
            acf:ACFProjects {
              content:projectContent {
                ... on WpPage_Acfprojects_ProjectContent_TextAndImage {
                  fieldGroupName
                  text
                  stats: projectStats {
                    location
                    client
                    commodity
                    contract
                    scope
                  }
                  rightImage {
                    gatsbyImage(width: 150, quality: 50)
                  }
                  asxLinks {
                    asxUrl {
                    title
                    url
                    }
                  }
                }
              }
            }
          }
        }
        }
      }
    }
  }
`

const TimelineDiv = ({data}) => {

  const timeline = data.wp.timeline.entries

  const Project = (props) => {

    if (props.input.project !== null || undefined ) {

      const projectData = props.input.project
      const stats = projectData.acf.content[0].stats
      return (
        <div className="project-content">
          <div className="project-text w-full">
            <h6>{projectData.title}</h6>
            <div className="project-info flex flex-col flex-nowrap md:flex-row">
              <ul class="stat-items md:max-h-[125px] w-3/4 self-center">
                <li class="stat-item">
                  <span className="stat-name">
                    Location
                  </span>
                  <span className="stat-val">
                    {stats.location}
                  </span>
                </li>
                <li class="stat-item">
                  <span className="icon"></span>
                  <span className="stat-name">Client</span>
                  <span className="stat-val">{stats.client} </span>
                </li>
                <li class="stat-item">
                  <span className="icon"></span>
                  <span className="stat-name">Commodity</span>
                  <span className="stat-val">{stats.commodity} </span>
                </li>
                <li class="stat-item">
                  <span className="icon"></span>
                  <span className="stat-name">Contract Duration</span>
                  <span className="stat-val">{stats.contract} </span>
                </li>
              </ul>
              <Link 
                className="project-link no-underline text-center normal-case block h-10 w-18 py-2 text-sm md:py-0 md:w-1/4 md:h-1/4 md:px-2 md:pt-1 md:self-center" 
                to={projectData.uri}
              >
                Read More
              </Link>
            </div>
          </div>
      </div>
      )
    }
    else return
  }

    return (
      <Layout>
        <CurveHero pageTitle="History" />
          <div className="overflow-x-hidden pt-18 bg-orange-100">
            <div className="timeline py-0 my-6">
              <div className="timeline__wrapper">
                <div className="timeline__single">
                  {timeline.map((entry) => (
                      <div className="timeline-entry">
                      <div className="timeline-entry--dot" data-sal="flip-up" data-sal-delay="300" data-sal-duration="1200" data-sal-easing="ease-out-back"></div>
                        <div className="timeline-entry__right timeline__text timeline__col" data-sal="slide-right" data-sal-delay="300" data-sal-duration="1200" data-sal-easing="ease">
                          <div className="text-content">
                            <h2>{entry.timelineHeading}</h2>
                            <p className="timeline-entry__lbl">{entry.timelineContent}</p>
                          </div>
                          {console.log(entry)}
                          {entry.asxLinks && 
                            <div className="pdf-link">
                              <a href={entry.asxLinks[0].asxUrl.url}>{entry.asxLinks[0].asxUrl.title}</a>
                            </div>
                          }
                          <Project input={entry} />
                        </div>
                        <div className="timeline-entry__left timeline__img timeline__col" data-sal="slide-left" data-sal-delay="300" data-sal-duration="1500" data-sal-easing="ease">
                          <GatsbyImage image={entry.timelineImage.gatsbyImage} alt="A kitten" />
                        </div>
                      </div>
                  ))}
                  </div>

                </div>
            </div>
          </div>
    </Layout>
    )

}


export const Head = () => <Seo title="Timeline"/>

export default TimelineDiv
